import { Session } from "@supabase/auth-helpers-nextjs";
import Link from "next/link";
import { handleLogout } from "@/app/api/supabaseHandlers";
import { useRouter } from "next/navigation";
import { use, useEffect, useState } from "react";

const SessionMenu = ({ session }: { session: Session | null }) => {
  const router = useRouter();
  const [isAdmin, setIsAdmin] = useState(false);
  const admin = ["queeeryday@gmail.com", "willyhuang18@gmail.com"];

  useEffect(() => {
    if (!session) return;
    //Check to see if the user is an admin
    if (session) {
      if (admin.includes(session?.user?.email)) {
        setIsAdmin(true);
      }
    }
  }, [session]);

  const logout = async () => {
    const result = await handleLogout();
    if (result === "SIGNED_OUT") {
      console.log("📢 [auth-button-client.tsx:13]", result);
      //Fix this refresh
      router.refresh();
      window.location.reload();
    }
  };
  return (
    <>
      {session ? (
        <>
          {isAdmin && (
            <Link
              href="/newEvent"
              className="rounded-md bg-yellow px-6 py-4 text-base font-bold text-white transition duration-300 ease-in-out hover:bg-opacity-80"
            >
              Add Your Event
            </Link>
          )}
          <div className="mx-4 flex-1">
            <button
              onClick={() => router.push("/profile")}
              className="rounded-md bg-primary px-6 py-4 text-base font-bold text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:shadow-signUp"
            >
              {session?.user?.user_metadata.name || "💥"}
            </button>
          </div>
        </>
      ) : (
        <>
          <Link
            href="/signin"
            className="hidden px-7 py-3 text-base font-bold text-dark hover:opacity-70 dark:text-white md:block"
          >
            Sign In
          </Link>
          <Link
            href="/signup"
            className="ease-in-up hidden rounded-md bg-primary px-8 py-3 text-base font-bold text-white transition duration-300 hover:bg-opacity-90 hover:shadow-signUp md:block md:px-9 lg:px-6 xl:px-9"
          >
            Sign Up
          </Link>
        </>
      )}
    </>
  );
};

export default SessionMenu;
