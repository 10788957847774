import { Menu } from "@/types/menu";

const menuData: Menu[] = [
  {
    id: 1,
    title: "Home",
    path: "/",
    newTab: false
  },
  // {
  //   id: 2,
  //   title: "About",
  //   path: "/about",
  //   newTab: false
  // },
  {
    id: 33,
    title: "LA Queer Events 🎟️",
    path: "/events",
    newTab: false
  },
  // {
  //   id: 3,
  //   title: "Add Your Event ✚",
  //   path: "/newEvent",
  //   newTab: false
  // },
  {
    id: 4,
    title: "links 🏳️‍🌈",
    newTab: false,
    submenu: [
      // {
      //   id: 49,
      //   title: "Profile",
      //   path: "/profile",
      //   newTab: false
      // },

      {
        id: 41,
        title: "About",
        path: "/about",
        newTab: false
      },
      {
        id: 3,
        title: "Support",
        path: "/contact",
        newTab: false
      }

      // {
      //   id: 43,
      //   title: "Event Grid Page",
      //   path: "/event",
      //   newTab: false
      // },
      // {
      //   id: 44,
      //   title: "Event Sidebar Page",
      //   path: "/event-sidebar",
      //   newTab: false
      // },
      // {
      //   id: 45,
      //   title: "Event Details Page",
      //   path: "/event-details",
      //   newTab: false
      // },
      // {
      //   id: 48,
      //   title: "Error Page",
      //   path: "/error",
      //   newTab: false
      // },
    ]
  }
];
export default menuData;
