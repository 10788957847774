import { createBrowserClient } from "@supabase/ssr";

// Get a supabase session
export const getSession = async () => {
  const supabase = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
  );

  const { data: session, error } = await supabase.auth.getSession();
  if (error) throw error;
  return session && session;
};

export const handleLogout = async () => {
  const supabase = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
  );

  const { error } = await supabase.auth.signOut();
  if (!error) {
    return "SIGNED_OUT";
  } else {
    throw error;
  }
};
