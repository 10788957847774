"use client";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import ScrollToTop from "@/components/ScrollToTop";
import "node_modules/react-modal-video/css/modal-video.css";
import "../styles/index.css";
import { Analytics } from "@vercel/analytics/react";
import { ReactQueryClientProvider } from "@/components/ReactQueryClientProvider";
export default function RootLayout({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <html suppressHydrationWarning lang="en">
      {/*
        <head /> will contain the components returned by the nearest parent
        head.js. Find out more at https://beta.nextjs.org/docs/api-reference/file-conventions/head
      */}
      <head />

      <body className="dark:bg-black">
        <ReactQueryClientProvider>
          <Providers>
            <Header />
            {children}
            <Footer />
            <ScrollToTop />
            <Analytics />
          </Providers>
        </ReactQueryClientProvider>
      </body>
    </html>
  );
}

import { Providers } from "./providers";
import { ReduxProvider } from "./ReduxProvider";
